
<script setup lang="ts">
    import { ref, watch, onMounted, onBeforeUnmount } from "vue";

    export interface IProps {
        reverse?: boolean;
    };

    const props = defineProps<IProps>();
    const model = defineModel<boolean>();

    const el = ref<HTMLElement>();

    let transition: string = "max-height 500ms ease";

    function getModel() {
        let value = model.value;
        if (props.reverse) {
            value = !value;
        }
        return value;
    }

    function update(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!el.value) {
                resolve();
                return;
            }
            if (getModel()) {
                el.value.style.transition = transition;
                el.value.style.maxHeight = el.value.scrollHeight + "px";
                // el.value.style.overflow = "unset";
            } else {
                if (!el.value.style.maxHeight) {
                    el.value.style.transition = "";
                    el.value.style.maxHeight = el.value.scrollHeight + "px";
                    // el.value.style.overflow = "unset";
                }
                setTimeout(() => {
                    if (!el.value) {
                        resolve();
                        return;
                    }
                    el.value.style.transition = transition;
                    el.value.style.maxHeight = "0";
                    el.value.style.overflow = "hidden";
                });
            }
            resolve();
        });
    }

    function onTransitionEnd(): void {
        if (!el.value) {
            return;
        }
        if (getModel()) {
            el.value.style.maxHeight = "";
            el.value.style.overflow = "unset";
        }
    }

    watch(model, update);

    onMounted(() => {
        transition = "max-height 0ms";
        update().then(() => {
            onTransitionEnd();
            setTimeout(() => {
                transition = "max-height 500ms ease";
                if (el.value) {
                    el.value.addEventListener("transitionend", onTransitionEnd);
                }
            });
        });
    });

    onBeforeUnmount(() => {
        if (el.value) {
            el.value.removeEventListener("transitionend", onTransitionEnd);
        }
    });
</script>

<template>
    <div ref="el" class="c-collapse" v-bind="$attrs">
        <slot />
    </div>
</template>

<style scoped>
    .c-collapse {
        overflow: hidden;
    }
</style>
